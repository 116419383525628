import { useEffect } from 'react';
import { Dialog } from '@headlessui/react'
import Popup from './Popup'
import { useTranslation } from 'react-i18next';

export default function ImageZoom(props) {
    const { t } = useTranslation(['page', 'audio']);
    const { isOpen, setIsOpen, image, setImage } = props

    useEffect(() => {
        if (image) {
            setIsOpen(true)
        }
    }, [image])

    useEffect(() => {
        if (!isOpen) {
            setTimeout(() =>{
               setImage(null)
            }, 1000);
        }
    }, [isOpen])

    const closeModal = () => {
        setIsOpen(false)
    }

    return <Popup {...props} >
        <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
            <Dialog.Title
                as="h3"
                className="text-2xl font-medium leading-6 text-wsd mb-6"
            >
                {t('image-zoom.title', { ns: 'audio' })}
            </Dialog.Title>
            <img className="w-full" src={props.image} alt="Zoomed Image" />
            <div className="mt-4">
                <button onClick={() => closeModal()} className="h-14 w-full p-2 mt-6 text-2xl rounded-full bg-wsd text-white font-semibold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-yellow-500">
                    {t('image-zoom.close', { ns: 'audio' })}
                </button>
            </div>
        </Dialog.Panel>
    </Popup>
}