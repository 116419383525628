import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext } from "react-router-dom";

import AnchorLink from 'react-anchor-link-smooth-scroll'
import { ArrowUpIcon } from '@heroicons/react/outline'

import Alert from './Alert';
import { trackGa } from '../utils/ga'
// Top: 0 takes us all the way back to the top of the page
// Behavior: smooth keeps it smooth!
const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
};

export default function Intro(props) {
    const [setTitle] = useOutletContext();
    const { t, i18n } = useTranslation(['errorMessage', 'page', 'audio']);

    useEffect(() => {
        document.title = t(props.title, { ns: 'page' });
        document.documentElement.lang = i18n.language === 'en' ? 'en' : i18n.language === 'tc' ? 'zh-HK' : 'zh-CN';
        document.getElementsByTagName('meta')["keywords"].content = t('meta-keywords', { ns: 'page' });
        document.getElementsByTagName('meta')["description"].content = t('meta-desc', { ns: 'page' });
        setTitle(document.title);

        scrollToTop();
        trackGa(i18n);
    }, [i18n.language])

    const lang = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [alertStatus, setAlertStatus] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const attractions = [
        {
            "title": 's1.title',
            "desc": 's1.script-1',
            "cover-img": "/img/spots/spot-1-cover.png"
        },
        {
            "title": 's2.title',
            "desc": 's2.script-1',
            "cover-img": "/img/spots/spot-2-cover.jpg"
        },
        {
            "title": 's3.title',
            "desc": 's3.script-1',
            "cover-img": "/img/spots/spot-3-cover.jpg"
        },
        {
            "title": 's4.title',
            "desc": 's4.script-1',
            "cover-img": "/img/spots/spot-4-cover.jpg"
        },
        {
            "title": 's5.title',
            "desc": 's5.script-1',
            "cover-img": "/img/spots/spot-5-cover.jpg"
        },
        {
            "title": 's6.title',
            "desc": 's6.script-1',
            "cover-img": "/img/spots/spot-6-cover.jpg"
        },
        {
            "title": 's7.title',
            "desc": 's7.script-1',
            "cover-img": "/img/spots/spot-7-cover.jpg"
        },
        {
            "title": 's8.title',
            "desc": 's8.script-1',
            "cover-img": "/img/spots/spot-8-cover.jpg"
        }
    ];

    return (
        <>
            <Alert status={alertStatus} text={alertText} setShow={setShowAlert} show={showAlert} />
            <div className="mb-6">

                <div className="lg:flex lg:pb-10 lg:-mt-6">
                    <div className="relative w-full">
                        <img className="w-full lg:w-2/3 lg:translate-y-10" src="/img/intro-bg.png" alt="" />
                        <div className="bg-wsd-cancel absolute top-8 p-4 md:top-28 md:right-16 lg:top-14 lg:right-12 md:w-2/3 mx-12">
                            <img className="mx-auto" src="/img/intro-logo-a.png" alt="Logo of Ex-Sham Shui Po Service Reservoir" />
                        </div>
                    </div>

                    <div className={`lg:w-5/6 relative pt-4 tablet:h-[680px] lg:h-[400px] ${i18n.language === 'en' ? 'md:h-[750px]' : 'md:h-[720px]'}`}>
                        <div className="absolute right-1 top-4 p-4">
                            <img className="w-16" src="/img/intro-corner.png" alt="" />
                        </div>
                        <div className={`absolute left-0 ${i18n.language === 'en' ? 'top-64 md:top-44' : 'top-44'} px-6 py-3 lg:max-w-xl`}>
                            <img className="lg:mt-20 mt-10 block w-full rounded-3xl" src="/img/bg-audio.png" alt="" />
                        </div>
                        <div className="lg:mt-10 mt-3 flex">
                            <div className="w-full p-4 mt-4">
                                <div className="relative w-full h-96">
                                    <div className="absolute top-0 left-0 inline-block text-wsd font-bold">
                                        <div className="bg-white flex text-xl flex-col mx-2 justify-center">
                                            <div className="flex w-fit mx-1 text-4xl border-b-2 pb-2 border-wsd-form border-dashed justify-start">
                                                <p className="text-wsd">{t('intro.audioguide', { ns: 'audio' })}</p>
                                            </div>
                                            <div className="flex flex-col mx-1 mt-2 justify-start text-wsd-form">
                                                <p className="text-wsd-form">{t('intro.title', { ns: 'audio' })}</p>
                                                <p className="text-wsd-form">{t('intro.desc', { ns: 'audio' })}</p>
                                            </div>
                                            <div className="flex flex-col text-gray-500 text-sm mx-1 mt-2 font-medium justify-start">
                                                <p className="text-gray-500">{t('intro.ps', { ns: 'audio' })}</p>
                                                <p className="text-gray-500">{t('intro.ps-to', { ns: 'audio' })}
                                                    <label className="ml-1 text-wsd underline underline-offset-2 cursor-pointer" onClick={() => lang('tc')}>{t('intro.tc', { ns: 'audio' })}</label> /
                                                    {/* <span className="ml-1 text-wsd cursor-pointer" onClick={() => lang('sc')}>{t('intro.sc', { ns: 'audio' })}</span> / */}
                                                    <label className="ml-1 text-wsd underline underline-offset-2 cursor-pointer" onClick={() => lang('en')}>{t('intro.en', { ns: 'audio' })}</label>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="z-10 mt-5 lg:flex lg:justify-center">
                    <div className={`lg:mt-40 mx-6 ${i18n.language === 'en' ? 'mt-28 md:mt-8' : 'mt-8'} lg:w-[400px]`}>
                        <a tabIndex="-1" href={`${process.env.REACT_APP_BOOKING_DOMAIN}/`}>
                            <div className={`flex justify-center items-center bg-wsd hover:bg-wsd-form focus:ring-4 focus:ring-yellow-500 h-12 w-full lg:max-w-[400px] p-2 text-xl rounded-full text-white font-semibold shadow-md focus:outline-none `}>
                                {t('intro.back-to-application', { ns: 'audio' })}
                            </div>
                        </a>
                    </div>
                </div>

                <div className="lg:mt-10 hidden 2xl:flex px-4 justify-center mt-6 mt-20">
                    <AnchorLink offset='20' href='#map'>
                        <div className={`${i18n.language === 'en' ? 'h-40' : 'h-32'} w-[170px] cursor-pointer bg-blue-50 bg-opacity-50 hover:bg-blue-200 focus:bg-blue-200 p-4 flex flex-col items-center px-1`}>
                            <img className="w-9" src="/img/ic-home-brown.png" alt="Go To The Floor Plan of Ex-Sham Shui Po Service Reservoir Section" />
                            <span className="w-30 inline-block text-gray-600 mt-2 text-md">{t('map-title-1', { ns: 'audio' })}</span>
                            <span className="w-30 inline-block text-gray-600 text-md">{t('map-title-2', { ns: 'audio' })}</span>
                        </div>
                    </AnchorLink>
                    {attractions.map((attraction, idx) => {
                        return <AnchorLink key={idx} offset='60' href={`#${idx + 1}`}>
                            <div className={`${i18n.language === 'en' ? 'h-40' : 'h-32'} w-[170px] cursor-pointer bg-blue-50 bg-opacity-50 p-4 hover:bg-blue-200 focus:bg-blue-200 flex flex-col items-center px-1`}>
                                <img className="w-9" src="/img/ic-location-blue.png" alt={`Go To ${attraction.title} Section`} />
                                <span className="w-[150px] text-center inline-block text-gray-600 mt-1 text-sm">{t('spot', { ns: 'audio' })} {idx + 1}</span>
                                <span className="w-[150px] text-center inline-block text-gray-600 mt-1 text-sm">{t(attraction.title, { ns: 'audio' })}</span>
                            </div>
                        </AnchorLink>
                    })}
                </div>

                <div className="lg:mt-10 hidden lg:flex 2xl:hidden px-4 justify-center mt-20">
                    <AnchorLink offset='20' href='#map'>
                        <div className={`${i18n.language === 'en' ? 'h-40' : 'h-32'} w-[160px] cursor-pointer bg-blue-50 bg-opacity-50 hover:bg-blue-200 focus:bg-blue-200 p-4 flex flex-col items-center px-1`}>
                            <img className="w-9" src="/img/ic-home-brown.png" alt="Go To The Floor Plan of Ex-Sham Shui Po Service Reservoir Section" />
                            <span className="w-30 inline-block text-gray-600 mt-2 text-md">{t('map-title-1', { ns: 'audio' })}</span>
                            <span className="w-30 inline-block text-gray-600 text-md">{t('map-title-2', { ns: 'audio' })}</span>
                        </div>
                    </AnchorLink>
                    {attractions.slice(0, 4).map((attraction, idx) => {
                        return <AnchorLink key={idx} offset='60' href={`#${idx + 1}`}>
                            <div className={`${i18n.language === 'en' ? 'h-40' : 'h-32'} w-[160px] cursor-pointer bg-blue-50 bg-opacity-50 p-4 hover:bg-blue-200 focus:bg-blue-200 flex flex-col items-center px-1`}>
                                <img className="w-9" src="/img/ic-location-blue.png" alt={`Go To ${attraction.title} Section`} />
                                <span className="w-[150px] text-center inline-block text-gray-600 mt-1 text-sm">{t('spot', { ns: 'audio' })} {idx + 1}</span>
                                <span className="w-[150px] text-center inline-block text-gray-600 mt-1 text-sm">{t(attraction.title, { ns: 'audio' })}</span>
                            </div>
                        </AnchorLink>
                    })}
                </div>

                <div className="hidden lg:flex 2xl:hidden px-4 justify-center">
                    {attractions.slice(4, 8).map((attraction, idx) => {
                        return <AnchorLink key={idx} offset='60' href={`#${idx + 5}`}>
                            <div className={`${i18n.language === 'en' ? 'h-40' : 'h-32'} w-[160px] cursor-pointer bg-blue-50 bg-opacity-50 p-4 hover:bg-blue-200 focus:bg-blue-200 flex flex-col items-center px-1`}>
                                <img className="w-9" src="/img/ic-location-blue.png" alt={`Go To ${attraction.title} Section`} />
                                <span className="w-[150px] text-center inline-block text-gray-600 mt-1 text-sm">{t('spot', { ns: 'audio' })} {idx + 5}</span>
                                <span className="w-[150px] text-center inline-block text-gray-600 mt-1 text-sm">{t(attraction.title, { ns: 'audio' })}</span>
                            </div>
                        </AnchorLink>
                    })}
                </div>

                <hr className="lg:mx-32 2xl:mx-0 hidden lg:block bg-gray-200 h-1" />

                <section id="map">
                    <div className="lg:mt-20 mt-10 flex justify-center">
                        <div className="max-w-2xl w-full flex px-4 justify-between h-12 border-y-2">
                            <div className="flex flex-none items-center px-1">
                                <img className="w-6 " src="/img/ic-home-brown.png" alt="" />
                                <span className="ml-6 inline-block text-md md:text-xl">{t('map-title-1', { ns: 'audio' })}{t('map-title-2', { ns: 'audio' })}</span>
                            </div>
                        </div>
                    </div>

                    <div className="border-b-2 border-dashed border-wsd-form flex justify-center">
                        <div className="mt-4 mb-8 w-full w-fit h-fit flex px-2 justify-between">
                            <div className="flex flex-none items-center w-fit h-fit">
                                <img src="/img/tour-map.png" alt="The Floor Plan of Ex-Sham Shui Po Service Reservoir" />
                            </div>
                        </div>
                    </div>
                </section>

                {attractions.map((attraction, idx) => {
                    return <div key={idx} className={`h-fit ${idx % 2 ? 'bg-yellow-50' : 'bg-wsd'} mt-16 mb-10 pb-12`}>
                        <section id={idx + 1}>
                            <div className="flex justify-center">
                                <div className="max-w-3xl -mt-10 w-full w-fit h-fit flex px-2 justify-between">
                                    <div className="flex flex-none items-center w-fit h-fit shadow-md">
                                        <img src={attraction['cover-img']} alt="The Image Preview of the Spot" />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center my-8">
                                <div className={`w-fit p-1 text-center text-md border-y-2 ${idx % 2 ? 'border-wsd-form text-wsd-form bg-yellow-50' : 'bg-wsd border-white text-white'}`}>
                                    <div className="flex px-2 justify-start">
                                        <div className="flex flex-none items-center px-1">
                                            <div className="mr-2 px-2 py-1 border-1 rounded-full font-meidum">
                                                {idx % 2 !== 0 && <img className="w-6" src="/img/ic-location-brown.png" alt="" />}
                                                {idx % 2 === 0 && <img className="w-6" src="/img/ic-location.png" alt="" />}
                                            </div>
                                        </div>

                                        <div className={`border-r-2 ${idx % 2 ? 'border-wsd-form' : 'border-gray-100'}`} />

                                        <div className="flex flex-1 justify-center items-center px-1">
                                            <div className="py-1 px-4 border-1 rounded-full text-md">
                                                {t('spot', { ns: 'audio' })} {idx + 1} - {t(attraction.title, { ns: 'audio' })}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* 
                            <div className="lg:flex lg:justify-center">
                                <div className={`border-y-2 border-dashed text-justify ${idx % 2 ? 'border-wsd-form text-wsd-form' : 'border-white text-white'}`}>
                                    <div className="max-w-3xl px-4 py-6">
                                        <span className="text-ellipsis overflow-hidden inline-block text-sm">{t(attraction.desc, { ns: 'audio' })}</span>
                                    </div>
                                </div>
                            </div> */}


                            <div className="lg:flex lg:justify-center w-full px-6 mt-6">
                                <Link to={`/attractions/${idx + 1}`}>
                                    <div type="button" className={`flex justify-center items-center ${idx % 2 ? 'bg-wsd-form text-white hover:bg-blue-700 hover:bg-wsd hover:text-white focus:ring-yellow-500' : 'bg-white text-wsd hover:bg-wsd-form hover:text-white focus:ring-yellow-500'} lg:w-[400px] h-12 w-full text-xl rounded-full font-semibold shadow-md  focus:outline-none focus:ring-4`}>
                                        {t('intro.enter', { ns: 'audio' })}
                                    </div>
                                </Link>
                            </div>


                        </section>
                    </div>
                })}

                <div className="flex w-[70px] ml-auto sticky bottom-20 lg:bottom-0 p-4 -mt-20">
                    <AnchorLink aria-label="Back To Top" href='#top' className="items-center rounded-full border border-transparent bg-wsd-form p-3 text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2">
                        <ArrowUpIcon title="Back To Top" className="h-4 w-4 lg:h-6 lg:w-6" />
                    </AnchorLink>
                </div>

            </div >
        </>
    );
}