import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import "react-datepicker/dist/react-datepicker.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Attraction from './components/Attraction';
import Intro from './components/Intro';
import Loading from './components/Loading';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} >
        <Route index element={<Intro title="title.home" />} />
        <Route path="attractions">
          <Route path=":attractionId" element={
            <Suspense fallback={<Loading />}>
              <Attraction title="title.attraction" />
            </Suspense>
          } />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
