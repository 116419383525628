import { useState, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext } from "react-router-dom";

import AnchorLink from 'react-anchor-link-smooth-scroll'
import { ArrowUpIcon } from '@heroicons/react/outline'
import { useParams } from 'react-router-dom';
import { get } from 'lodash'

import Alert from './Alert';
import Slide from './Slide';
import { trackGa } from '../utils/ga'

// Top: 0 takes us all the way back to the top of the page
// Behavior: smooth keeps it smooth!
const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
};

export default function Attraction(props) {
    let { attractionId } = useParams();

    const [slideOpen, setSlideOpen] = useState(false)
    const [track, setTrack] = useState(null)
    const [setTitle] = useOutletContext();
    const { t, i18n } = useTranslation(['errorMessage', 'page', 'audio']);

    useEffect(() => {
        document.title = t(props.title, { ns: 'page' });
        document.documentElement.lang = i18n.language === 'en' ? 'en' : i18n.language === 'tc' ? 'zh-HK' : 'zh-CN';
        document.getElementsByTagName('meta')["keywords"].content = t('meta-keywords', { ns: 'page' });
        document.getElementsByTagName('meta')["description"].content = t('meta-desc', { ns: 'page' });
        setTitle(document.title);

        scrollToTop();
        trackGa(i18n)
    }, [i18n.language])

    const lang = (lng) => {
        i18n.changeLanguage(lng);
    };

    const [alertStatus, setAlertStatus] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const attractions = [
        {
            "title": 's1.title',
            "desc": 's1.script-1',
            "tracks": [
                {
                    name: 's1.script-title-1',
                    script: 's1.script-1',
                    length: i18n.language === 'en' ? '02:10' : '02:13',
                    file: i18n.language === 'en' ? "/videos/spot-1-1-en.mp4" : "/videos/spot-1-1-tc.mp4"
                },
                {
                    name: 's1.script-title-2',
                    script: 's1.script-2',
                    length: i18n.language === 'en' ? '01:12' : '00:55',
                    file: i18n.language === 'en' ? "/videos/spot-1-2-en.mp4" : "/videos/spot-1-2-tc.mp4"
                },
            ],
            "cover-img": "/img/spots/spot-1-cover.png",
            "images": [
                { img: "/img/spots/spot-1-001.jpg", caption: '' },
                { img: "/img/spots/spot-1-002.jpg", caption: '' }
            ],
            "sound-track-images": [
                { img: "/img/spots/spot-1-001.jpg" },
                { img: "/img/spots/spot-1-002.jpg" }
            ]
        },
        {
            "title": 's2.title',
            "desc": 's2.script-1',
            "tracks": [
                {
                    name: 's2.script-title-1',
                    script: 's2.script-1',
                    length: i18n.language === 'en' ? '01:18' : '01:06',
                    file: i18n.language === 'en' ? "/videos/spot-2-1-en.mp4" : "/videos/spot-2-1-tc.mp4"
                },
                {
                    name: 's2.script-title-2',
                    script: 's2.script-2',
                    length: i18n.language === 'en' ? '01:11' : '01:26',
                    file: i18n.language === 'en' ? "/videos/spot-2-2-en.mp4" : "/videos/spot-2-2-tc.mp4"
                }
            ],
            "cover-img": "/img/spots/spot-2-cover.jpg",
            "images": [
                { img: "/img/spots/spot-2-001.jpg", caption: '' },
                { img: "/img/spots/spot-2-002.jpg", caption: t('s2.photo-2-caption', { ns: 'audio' }) },
                { img: "/img/spots/spot-2-003.jpg", caption: t('s2.photo-3-caption', { ns: 'audio' }) },
            ],
            "sound-track-images": [
                { img: "/img/spots/spot-2-cover.jpg" },
                { img: "/img/spots/spot-2-001.jpg" }
            ]
        },
        {
            "title": 's3.title',
            "desc": 's3.script-1',
            "tracks": [
                {
                    name: 's3.script-title-1',
                    script: 's3.script-1',
                    length: i18n.language === 'en' ? '00:57' : '00:53',
                    file: i18n.language === 'en' ? "/videos/spot-3-1-en.mp4" : "/videos/spot-3-1-tc.mp4"
                },
                {
                    name: 's3.script-title-2',
                    script: 's3.script-2',
                    length: i18n.language === 'en' ? '00:48' : '00:40',
                    file: i18n.language === 'en' ? "/videos/spot-3-2-en.mp4" : "/videos/spot-3-2-tc.mp4"
                },
            ],
            "cover-img": "/img/spots/spot-3-cover.jpg",
            "images": [
                { img: "/img/spots/spot-3-001.jpg", caption: t('s3.photo-1-caption', { ns: 'audio' }) }
            ],
            "sound-track-images": [
                { img: "/img/spots/spot-3-cover.jpg" },
                { img: "/img/spots/spot-3-001.jpg" }
            ]
        },
        {
            "title": 's4.title',
            "desc": 's4.script-1',
            "tracks": [
                {
                    name: 's4.script-title-1',
                    script: 's4.script-1',
                    length: i18n.language === 'en' ? '01:26' : '01:28',
                    file: i18n.language === 'en' ? "/videos/spot-4-1-en.mp4" : "/videos/spot-4-1-tc.mp4"
                }
            ],
            "cover-img": "/img/spots/spot-4-cover.jpg",
            "images": [
                { img: "/img/spots/spot-4-001.jpg", caption: '' },
                { img: "/img/spots/spot-4-002.jpg", caption: t('s4.photo-2-caption', { ns: 'audio' }) },
                { img: "/img/spots/spot-4-003.jpg", caption: t('s4.photo-3-caption', { ns: 'audio' }) },
                { img: "/img/spots/spot-4-004.jpg", caption: t('s4.photo-4-caption', { ns: 'audio' }) },
                { img: "/img/spots/spot-4-005.jpg", caption: t('s4.photo-5-caption', { ns: 'audio' }) },
                { img: "/img/spots/spot-4-006.jpg", caption: t('s4.photo-6-caption', { ns: 'audio' }) },
            ],
            "sound-track-images": [
                { img: "/img/spots/spot-4-001.jpg" },
            ]
        },
        {
            "title": 's5.title',
            "desc": 's5.script-1',
            "tracks": [
                {
                    name: 's5.script-title-1',
                    script: 's5.script-1',
                    length: i18n.language === 'en' ? '01:00' : '01:13',
                    file: i18n.language === 'en' ? "/videos/spot-5-1-en.mp4" : "/videos/spot-5-1-tc.mp4"
                }
            ],
            "cover-img": "/img/spots/spot-5-cover.jpg",
            "images": [
                { img: "/img/spots/spot-5-001.jpg", caption: '' },
                { img: "/img/spots/spot-5-002.jpg", caption: t('s5.photo-2-caption', { ns: 'audio' }) },
                { img: "/img/spots/spot-5-003.jpg", caption: t('s5.photo-3-caption', { ns: 'audio' }) },
                { img: "/img/spots/spot-5-004.jpg", caption: t('s5.photo-4-caption', { ns: 'audio' }) },
            ],
            "sound-track-images": [
                { img: "/img/spots/spot-5-001.jpg" },
            ]
        },
        {
            "title": 's6.title',
            "desc": 's6.script-1',
            "tracks": [
                {
                    name: 's6.script-title-1',
                    script: 's6.script-1',
                    length: i18n.language === 'en' ? '01:00' : '01:01',
                    file: i18n.language === 'en' ? "/videos/spot-6-1-en.mp4" : "/videos/spot-6-1-tc.mp4"
                },
            ],
            "cover-img": "/img/spots/spot-6-cover.jpg",
            "images": [
                { img: "/img/spots/spot-6-001.jpg", caption: '' }
            ],
            "sound-track-images": [
                { img: "/img/spots/spot-6-001.jpg" },
            ]
        },
        {
            "title": 's7.title',
            "desc": 's7.script-1',
            "tracks": [
                {
                    name: 's7.script-title-1',
                    script: 's7.script-1',
                    length: i18n.language === 'en' ? '01:21' : '01:09',
                    file: i18n.language === 'en' ? "/videos/spot-7-1-en.mp4" : "/videos/spot-7-1-tc.mp4"
                },
                {
                    name: 's7.script-title-2',
                    script: 's7.script-2',
                    length: i18n.language === 'en' ? '01:49' : '01:29',
                    file: i18n.language === 'en' ? "/videos/spot-7-2-en.mp4" : "/videos/spot-7-2-tc.mp4"
                },
                {
                    name: 's7.script-title-3',
                    script: 's7.script-3',
                    length: i18n.language === 'en' ? '01:07' : '00:57',
                    file: i18n.language === 'en' ? "/videos/spot-7-3-en.mp4" : "/videos/spot-7-3-tc.mp4"
                },
                {
                    name: 's7.script-title-4',
                    script: 's7.script-4',
                    length: i18n.language === 'en' ? '02:17' : '02:09',
                    file: i18n.language === 'en' ? "/videos/spot-7-4-en.mp4" : "/videos/spot-7-4-tc.mp4"
                },
            ],
            "cover-img": "/img/spots/spot-7-cover.jpg",
            "images": [
                { img: "/img/spots/spot-7-001.jpg", caption: '' }
            ],
            "sound-track-images": [
                { img: "/img/spots/spot-7-001.jpg" },
                { img: "/img/spots/spot-7-001.jpg" },
                { img: "/img/spots/spot-7-001.jpg" },
                { img: "/img/spots/spot-7-001.jpg" },
            ]
        },
        {
            "title": 's8.title',
            "desc": 's8.script-1',
            "tracks": [
                {
                    name: 's8.script-title-1',
                    script: 's8.script-1',
                    length: i18n.language === 'en' ? '01:22' : '01:18',
                    file: i18n.language === 'en' ? "/videos/spot-8-1-en.mp4" : "/videos/spot-8-1-tc.mp4"
                },
                {
                    name: 's8.script-title-2',
                    script: 's8.script-2',
                    length: i18n.language === 'en' ? '01:09' : '01:01',
                    file: i18n.language === 'en' ? "/videos/spot-8-2-en.mp4" : "/videos/spot-8-2-tc.mp4"
                },
            ],
            "cover-img": "/img/spots/spot-8-cover.jpg",
            "images": [
                { img: "/img/spots/spot-8-001.jpg", caption: '' },
                { img: "/img/spots/spot-8-002.jpg", caption: '' },
                { img: "/img/spots/spot-8-003.jpg", caption: '' }
            ],
            "sound-track-images": [
                { img: "/img/spots/spot-8-002.jpg" },
                { img: "/img/spots/spot-8-001.jpg" },
            ]
        }
    ];

    let attraction = null;
    try {
        const targetIdx = parseInt(attractionId) - 1;
        attraction = get(attractions, `${targetIdx}`, null);
    } catch (err) {
        console.log(err)
    }


    return (
        <>
            <Alert status={alertStatus} text={alertText} setShow={setShowAlert} show={showAlert} />
            <div className="mb-6">

                <div className="lg:flex lg:pb-10 lg:-mt-6">
                    <div className="relative w-full">
                        <img className="w-full lg:w-2/3 lg:translate-y-10" src="/img/intro-bg.png" alt="" />
                        <div className="bg-wsd-cancel absolute top-8 p-4 md:top-28 md:right-16 lg:top-14 lg:right-12 md:w-2/3 mx-12">
                            <img className="mx-auto" src="/img/intro-logo-a.png" alt="Logo of Ex-Sham Shui Po Service Reservoir" />
                        </div>
                    </div>

                    <div className={`lg:w-5/6 relative pt-4 tablet:h-[680px] lg:h-[400px] ${i18n.language === 'en' ? 'md:h-[750px]' : 'md:h-[500px]'}`}>
                        <div className="absolute right-1 top-4 p-4">
                            <img className="w-16" src="/img/intro-corner.png" alt="" />
                        </div>
                        <div className={`absolute left-0 ${i18n.language === 'en' ? 'top-64 md:top-44' : 'top-44'} px-6 py-3 lg:max-w-xl`}>
                            <img className="lg:mt-20 mt-10 block w-full rounded-3xl" src="/img/bg-audio.png" alt="" />
                        </div>
                        <div className="lg:mt-10 mt-3 flex">
                            <div className="w-full p-4 mt-4">
                                <div className="relative w-full h-96">
                                    <div className="absolute top-0 left-0 inline-block text-wsd font-bold">
                                        <div className="bg-white flex text-xl flex-col mx-2 justify-center">
                                            <div className="flex w-fit mx-1 text-4xl border-b-2 pb-2 border-wsd-form border-dashed justify-start">
                                                <p className="text-wsd">{t('intro.audioguide', { ns: 'audio' })}</p>
                                            </div>
                                            <div className="flex flex-col mx-1 mt-2 justify-start text-wsd-form">
                                                <p className="text-wsd-form">{t('intro.title', { ns: 'audio' })}</p>
                                                <p className="text-wsd-form">{t('intro.desc', { ns: 'audio' })}</p>
                                            </div>
                                            <div className="flex flex-col text-gray-500 text-sm mx-1 mt-2 font-medium justify-start">
                                                <p className="text-gray-500">{t('intro.ps', { ns: 'audio' })}</p>
                                                <p className="text-gray-500">{t('intro.ps-to', { ns: 'audio' })}
                                                    <label className="ml-1 text-wsd underline underline-offset-2 cursor-pointer" onClick={() => lang('tc')}>{t('intro.tc', { ns: 'audio' })}</label> /
                                                    {/* <span className="ml-1 text-wsd cursor-pointer" onClick={() => lang('sc')}>{t('intro.sc', { ns: 'audio' })}</span> / */}
                                                    <label className="ml-1 text-wsd underline underline-offset-2 cursor-pointer" onClick={() => lang('en')}>{t('intro.en', { ns: 'audio' })}</label>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Link to="/">
                    <div className="z-10 mt-5 lg:flex lg:justify-center">
                        <div className={`lg:mt-40 md:mt-72 mx-6 ${i18n.language === 'en' ? 'mt-28 md:mt-8' : 'mt-8'} lg:w-[400px]`}>
                            <div className={`flex justify-center items-center bg-wsd hover:bg-wsd-form focus:ring-4 focus:ring-yellow-500 h-12 w-full lg:max-w-[400px] p-2 text-xl rounded-full text-white font-semibold shadow-md focus:outline-none `}>
                                {t('intro.back-to-select-spot', { ns: 'audio' })}
                            </div>
                        </div>
                    </div>
                </Link>

                {attraction && <Fragment>
                    <div className="lg:mb-16 mt-10 flex justify-center">
                        <div className="max-w-2xl w-full flex px-4 justify-between h-fit py-2 my-1 border-y-2">
                            <div className="flex flex-none items-center px-1">
                                <img className="w-6 " src={`/img/ic-location-blue.png`} alt="" />
                                <span className="break-words w-[300px] md:w-full mt-1 ml-6 inline-block text-md md:text-lg">{t('spot', { ns: 'audio' })} {attractionId} - {t(attraction.title, { ns: 'audio' })}</span>
                            </div>
                        </div>
                    </div>

                    <div className={`lg:mt-4 h-fit bg-wsd pb-10`}>
                        <div className="flex justify-center">
                            <div className="lg:-mt-10 max-w-2xl w-full w-fit h-fit flex justify-between">
                                <div className="flex flex-none items-center w-fit h-fit shadow-md">
                                    <img src={attraction['cover-img']} alt="The Image Preview of the Spot" />
                                </div>
                            </div>
                        </div>


                        {/* <div className="lg:flex lg:justify-center">
                            <div className={`lg:max-w-4xl mt-6 mb-2 border-y-2 border-dashed text-justify border-white text-white`}>
                                <div className="px-4 py-6">
                                    <span className="inline-block text-sm lg:text-lg">{t(attraction.desc, { ns: 'audio' })}</span>
                                </div>
                            </div>
                        </div> */}

                    </div><Slide open={slideOpen} setOpen={setSlideOpen} attraction={attraction} track={track} setTrack={setTrack} />
                    <div className="grid grid-cols-2 lg:grid-cols-4 bg-wsd mb-10 pb-4 px-2">
                        {attraction.tracks.map((track, idx) => {
                            return <div key={idx} onClick={() => setTrack({ ...track, id: idx + 1 })} className={`cursor-pointer h-fit bg-wsd p-2`}>
                                <div className="flex justify-center rounded-lg">
                                    <div className="max-w-lg w-full flex justify-between">
                                        <div className="rounded-t-lg flex flex-none items-center w-full h-[120px] md:h-[260px] shadow-md">
                                            <div className="relative w-full h-[120px] md:h-[260px]">
                                                <img className="w-full h-[120px] md:h-[260px] object-fill rounded-t-lg " src={get(attraction, `sound-track-images.${idx}.img`, '')} alt={get(attraction, `sound-track-images.${idx}.caption`, 'Spot Cover Image')} />
                                                <div className="items-center bg-opacity-50 bg-black p-1 flex flex-col w-18 absolute lg:top-52 top-16 right-2 text-white text-sm">
                                                    <span>{t('audio', { ns: 'audio' })} {idx + 1}</span>
                                                    <span>({track.length})</span>
                                                </div>
                                                <div className="bg-white items-center bg-opacity-50 p-1 flex flex-col w-18 absolute lg:top-56 top-20 left-2 text-white text-sm">
                                                    <span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                                                    </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${i18n.language === 'en' ? 'h-[140px]' : 'h-[110px]'} overflow-scroll flex justify-center rounded-b-lg bg-white text-justify text-wsd-form`}>
                                    <div className="px-2 py-3 md:px-3 md:py-4">
                                        <span className="inline-block text-sm lg:text-lg">{t(track.name, { ns: 'audio' })}</span>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </Fragment>}



                <div className="flex w-[70px] ml-auto sticky bottom-20 lg:bottom-0 p-4 -mt-20">
                    <AnchorLink aria-label="Back To Top" href='#top' className="items-center rounded-full border border-transparent bg-wsd-form p-3 text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2">
                        <ArrowUpIcon title="Back To Top" className="h-4 w-4 lg:h-6 lg:w-6" />
                    </AnchorLink>
                </div>

            </div >
        </>
    );
}