
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { i18n, t } = useTranslation(['footer']);

    return <footer className="flex flex-col justify-center mt-12">

        <div className="text-white bg-wsd lg:flex lg:justify-center border-y-2 border-blue-500">
            <div className="py-2 flex flex-col">
                <div className="flex justify-center text-sm lg:text-xl">
                    <nav className="flex flex-wrap lg:flex-nowrap">
                        <div className="lg:w-30 text-center px-2">{t('section.sitemap', { ns: 'footer' })} : </div>
                        <a href={`/?lang=${i18n.language}`}>
                            <div className="px-4 lg:px-8">{t('section.main', { ns: 'footer' })}</div>
                        </a>
                    </nav>
                </div>
            </div>
        </div>

        <div className="flex justify-center items-center space-x-4 lg:space-x-10 px-4 py-2 lg:pt-4 lg:py-6">
            <a href={`https://www.ogcio.gov.hk/${i18n.language}/our_work/community/web_mobileapp_accessibility/nurturing_expertise/recognition_scheme/index.html`} target="_blank" className="max-w-sm">
                <img src="/img/footer1.png" alt="Web Accessibility Recognition Scheme" />
            </a>
            <a href="http://www.w3.org/WAI/WCAG2AA-Conformance" target="_blank" className="max-w-sm">
                <img src="/img/footer2.png" alt="Web Content Accessibility Guidelines" />
            </a>
            <a href={`http://www.brandhk.gov.hk/html/${i18n.language}/index.html`} target="_blank" className="max-w-sm">
                <img src="/img/footer3.gif" alt="Brand Hong Kong" />
            </a>
        </div>

        <div className="lg:flex lg:justify-between border-t-2 border-gray-200">
            <div className="text-sm">
                <nav className="mt-2 flex">
                    <div className="border-r px-3">2022 ©</div>
                    <a href={`https://www.waterconservation.gov.hk/${i18n.language}/important-notices/index.html`} target="_blank">
                        <div className="border-r px-3">{t('section.important-notice', { ns: 'footer' })}</div>
                    </a>
                    <a href={`https://www.waterconservation.gov.hk/${i18n.language}/privacy-policy/index.html`} target="_blank">
                        <div className="border-r px-3">{t('section.privacy-policy', { ns: 'footer' })}</div>
                    </a>
                    <a href={`https://www.waterconservation.gov.hk/${i18n.language}/contact-us/index.html`} target="_blank">
                        <div className="px-3">{t('section.contact-us', { ns: 'footer' })}</div>
                    </a>
                </nav>
            </div>
            <div>
                <p className="ml-3 mr-1 mt-2 mb-2 text-sm">{t('section.date', { ns: 'footer' })}: <span className="">2022-09-08</span></p>
            </div>
        </div>
    </footer>
}