/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState, useRef } from 'react'
import { Disclosure, Dialog, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player'
import Dexie from 'dexie'
import { sanitize } from 'dompurify';

import ImageZoom from './ImageZoom';
import Alert from './Alert';

const ALERT_DISPLAY_TIME_IN_MS = 2000;

export default function Slide({ open, setOpen, track, setTrack, attraction }) {
    const { t, i18n } = useTranslation(['errorMessage', 'page', 'audio']);
    const [zoomImage, setZoomImage] = useState(false);
    const [image, setImage] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [url, setUrl] = useState(track?.file);
    const videoRef = useRef();

    const [alertStatus, setAlertStatus] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const [downloadSize, setDownloadSize] = useState(0);
    const [isDownloaded, setIsDownloaded] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [db, setDb] = useState(null);

    const disclosures = [
        {
            'title': t('slide.disclosure-1', { ns: 'audio' }),
            'content': <div className="flex flex-col space-y-4">
                {attraction.images.map((image, index) => {
                    return <div key={index} class="flex flex-col"><img className="cursor-zoom-in" key={index} onClick={() => setImage(image.img)} src={image.img} alt={image.caption ? image.caption : `The Image Preview ${index} of The Spot`} />
                        <p className={`${i18n.language === 'en' ? 'text-justify' : 'text-center'} mt-4 font-medium text-lg text-gray-700`}>{image.caption}</p>
                    </div>
                })}
            </div>
        },
        {
            'title': t('slide.disclosure-2', { ns: 'audio' }),
            'content': <div>
                <p className="px-2 md:px-6 py-4 leading-8"><div dangerouslySetInnerHTML={{ __html: sanitize(track ? t(track.script, { ns: 'audio' }) : '') }} /></p></div>
        }
    ]

    useEffect(() => {

        const indexedDb = new Dexie('audio-guide-db');
        indexedDb.version(1).stores({
            audioFiles: 'fileName, video'
        });

        indexedDb.open().then(function () {
            // Database opened successfully
            console.log('Indexed DB Open Successfully')
        }).catch(function (error) {
            // Error occurred
            console.error(error);
            displayAlert('Success', t('slide.unable-download', { ns: 'audio' }))
        });

        setDb(indexedDb)

        async function getDownloadSize(track) {
            if (!track) return 0;
            let blob = await fetch(track.file).then(r => r.blob());
            var sizeInMB = (blob.size / (1024 * 1024)).toFixed(2);
            setDownloadSize(sizeInMB)
        }

        async function setupVideoFromCache(track) {
            if (!track) return;
            const audioFile = await db.audioFiles.where({
                fileName: `${track.name}-${i18n.language}`
            }).first();
            if (audioFile) {
                setIsDownloaded(true);
                const b64video = audioFile.video;
                displayAlert('Success', t('slide.loaded-from-cache', { ns: 'audio' }))
                setUrl(b64video)
            } else {
                setIsDownloaded(false);
            }
        }

        if (track) {
            setOpen(true);
            setUrl(track.file)
        }
        getDownloadSize(track);
        setupVideoFromCache(track)
    }, [track])

    useEffect(() => {
        if (!open) {
            setTrack(null)
        }
    }, [open])

    const download = async (track) => {
        try {
            setIsDownloading(true);
            let blob = await fetch(track.file).then(r => r.blob());
            let reader = new FileReader();
            reader.readAsDataURL(blob); // converts the blob to base64 and calls onload
            reader.onload = async function () {
                await db.audioFiles.put({
                    fileName: `${track.name}-${i18n.language}`,
                    video: reader.result
                });
                setIsDownloading(false);
                displayAlert('Success', t('slide.download-ok', { ns: 'audio' }))
                setIsDownloaded(true);
            };
        } catch (error) {
            console.error(error);
            displayAlert('Failed', t('slide.download-failed', { ns: 'audio' }))
        }
    }

    const cleanupDownloaded = async (track) => {
        try {
            await db.audioFiles.where({
                fileName: `${track.name}-${i18n.language}`
            }).delete();
            displayAlert('Success', t('slide.removed-from-cache', { ns: 'audio' }))
            setIsDownloaded(false);
        } catch (error) {
            console.error(error);
            displayAlert('Success', t('slide.unable-cache-remove', { ns: 'audio' }))
        }
    }

    const displayAlert = (type, objOrMsg) => {
        if (type === 'FAILED') {
            const errMsg = typeof (objOrMsg) === 'string' ? objOrMsg : objOrMsg?.response?.data?.message;
            if (errMsg) {
                setAlertStatus('FAILED');
                setAlertText(errMsg);
                setShowAlert(true);
            }
        } else {
            setAlertStatus('SUCCESS');
            setAlertText(objOrMsg);
        }

        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
            setAlertText('');
        }, ALERT_DISPLAY_TIME_IN_MS);
    }

    return (
        <>
            <Alert status={alertStatus} text={alertText} setShow={setShowAlert} show={showAlert} />
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <ImageZoom
                        setImage={setImage}
                        isOpen={zoomImage}
                        setIsOpen={setZoomImage}
                        image={image}
                    />
                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md lg:max-w-lg">
                                        {track && <div className="flex h-full flex-col overflow-y-scroll bg-white pb-6 shadow-xl">
                                            <div className="relative flex-1">
                                                <div className="w-full flex justify-center rounded-lg">
                                                    <div className="w-full">
                                                        <div className="flex flex-none items-center shadow-md">
                                                            <div className="relative w-full">
                                                                {/* {!playing && <div onClick={() => setPlaying(true)} className="z-50 cursor-pointer bg-white items-center bg-opacity-70 p-1 flex flex-col w-24 top-1/3 absolute left-0 right-0 mx-auto text-white text-sm">
                                                                    <span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="lg:w-20 lg:h-20 w-14 h-14">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                                                                    </svg>
                                                                    </span>
                                                                </div>} */}
                                                                <ReactPlayer
                                                                    onClickPreview={() => setPlaying(false)}
                                                                    url={url}
                                                                    width='100%'
                                                                    ref={videoRef}
                                                                    height='100%'
                                                                    playing={playing}
                                                                    controls={true}
                                                                    config={{
                                                                        file: {
                                                                            attributes: {
                                                                                preload: isDownloaded ? 'auto' : 'none',
                                                                                playsInline: true
                                                                            }
                                                                        }
                                                                    }}
                                                                    onError={(error) => {
                                                                        console.error('[VIDEO PLAYER ERROR]', error);
                                                                    }}
                                                                    onPause={() => {
                                                                        setPlaying(false);
                                                                    }}
                                                                    onPlay={() => {
                                                                        setPlaying(true);
                                                                    }}
                                                                />
                                                                {/* <button
                                                                    type="button"
                                                                    className="p-1 absolute top-2 right-2 rounded-md bg-black bg-opacity-50 text-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                                                    onClick={() => setOpen(false)}
                                                                >
                                                                    <div className='flex'>
                                                                        <span>{t('slide.close', { ns: 'audio' })}</span>
                                                                        <XCircleIcon className="lg:ml-1 h-6 w-6" />
                                                                    </div>
                                                                </button> */}
                                                                {/* {videoRef.current && <div className="items-center bg-opacity-50 bg-black p-1 flex flex-col w-18 absolute top-0 left-0 text-white text-sm lg:text-lg">
                                                                    <span>{t('audio', { ns: 'audio' })} {track.id}</span>
                                                                    {trackLength}
                                                                </div>} */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`lg:text-center rounded-b-lg bg-white text-justify text-wsd-form`}>
                                                    <div className="px-3 py-6">
                                                        <span className="inline-block text-md font-medium">{t(track.name, { ns: 'audio' })}</span>
                                                    </div>
                                                </div>
                                                {downloadSize > 0 && <div className="lg:flex lg:justify-center w-full px-8">
                                                    {!isDownloaded && <button onClick={() => download(track)} className={`bg-white border-2 border-wsd text-wsd focus:ring-yellow-500 lg:w-[350px] pb-1 h-14 w-full md:text-lg text-md rounded-full font-medium shadow-md focus:outline-none focus:ring-4`}>
                                                        <div className="flex justify-center px-4">
                                                            {!isDownloading && <img className="w-7 h-7 mt-1.5 pt-0.5 mr-3 ml-1" src="/img/ic-download.png" alt="Download Audio" />}
                                                            {isDownloading && <svg className="animate-spin mt-1.5 mr-4 h-6 w-6 text-wsd" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>}
                                                            <span className="mt-2">{t('slide.download', { ns: 'audio' })} ({downloadSize} MB)</span>
                                                        </div>
                                                    </button>}
                                                    {isDownloaded && <div><button className={`bg-white border-2 border-wsd text-wsd focus:ring-yellow-500 lg:w-[350px] h-12 w-full text-xl rounded-full font-semibold shadow-md`}>
                                                        <div className="cursor-not-allowed flex justify-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mt-0.5 w-8 h-8 mr-2 text-green-700">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                            </svg>
                                                            <span className="mt-1">{t('slide.downloaded', { ns: 'audio' })}</span>
                                                        </div>
                                                    </button>
                                                        <p onClick={() => cleanupDownloaded(track)} className="cursor-pointer text-center underline-offset-2 underline mt-6 text-sm text-wsd font-medium">{t('slide.clean-up', { ns: 'audio' })}</p>
                                                    </div>}
                                                </div>}

                                                <div className="flex justify-center w-full px-4">
                                                    <div className="w-full rounded-2xl bg-white p-2 my-2">
                                                        {disclosures.map((ap, idx) => {
                                                            return <Disclosure key={idx}>
                                                                {({ open }) => (
                                                                    <>
                                                                        <Disclosure.Button className={`${open ? 'rounded-t-3xl' : 'rounded-full'} mt-4 items-center flex w-full h-14 justify-between bg-wsd px-4 py-2 font-medium text-lg text-white`}>
                                                                            <span className="ml-2">{ap.title}</span>
                                                                            {!!open && <img className="w-6" src="/img/ic-collapse.png" alt="Collapse" />}
                                                                            {!open && <img className="w-4" src="/img/ic-expand.png" alt="Expand" />}
                                                                        </Disclosure.Button>
                                                                        <Transition
                                                                            enter="transition duration-100 ease-out"
                                                                            enterFrom="transform scale-95 opacity-0"
                                                                            enterTo="transform scale-100 opacity-100"
                                                                            leave="transition duration-75 ease-out"
                                                                            leaveFrom="transform scale-100 opacity-100"
                                                                            leaveTo="transform scale-95 opacity-0"
                                                                        >
                                                                            <Disclosure.Panel className="p-4 rounded-b-3xl text-justify text-md bg-blue-50 text-gray-600">{ap.content}
                                                                            </Disclosure.Panel>
                                                                        </Transition>
                                                                    </>
                                                                )}
                                                            </Disclosure>
                                                        })}

                                                    </div>
                                                </div>

                                                <div onClick={() => setOpen(false)} className="flex justify-center w-full px-8 mt-4">
                                                    <button className={`w-1/2 bg-wsd border-2 border-wsd text-white focus:ring-yellow-500 h-14 px-4 text-xl rounded-full pb-1 shadow-md focus:outline-none focus:ring-4`}>
                                                        <div className="flex justify-center">
                                                            <span className="mt-1">{t('slide.close', { ns: 'audio' })}</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>}
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}