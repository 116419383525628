import './App.css';
import { useState } from 'react'
import { Outlet } from "react-router-dom";

import Header from './components/Header'
import Footer from './components/Footer'

function App() {
  const [title, setTitle] = useState('');

  return (
    <div className="w-screen lg:container lg:mx-auto">
      <Header title={title} />
      <Outlet context={[setTitle]} />
      <Footer />
    </div>
  );
}

export default App;
