import { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react'
import { useTranslation } from 'react-i18next';
import {
    WeiboShareButton,
    WeiboIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterShareButton,
    TwitterIcon,
    FacebookShareButton,
    FacebookIcon
} from "react-share";
import { useLocation } from "react-router-dom"

import Popup from './Popup'


export default function Social(props) {
    const { t } = useTranslation(['social']);
    const [shares, setShares] = useState([]);
    const location = useLocation();

    useEffect(() => {
        setShares([
            {
                button: <WeiboShareButton
                    url={`${process.env.REACT_APP_SHARE_DOMAIN}${location.pathname}`}
                    title={props.title}
                    image={`${process.env.REACT_APP_SHARE_DOMAIN}/img/intro-bg.png`}
                >
                    <WeiboIcon size={46} round />
                </WeiboShareButton>
            },
            {
                button: <TwitterShareButton
                    url={`${process.env.REACT_APP_SHARE_DOMAIN}${location.pathname}`}
                    title={props.title}
                >
                    <TwitterIcon size={46} round />
                </TwitterShareButton>
            },
            {
                button: <FacebookShareButton
                    url={`${process.env.REACT_APP_SHARE_DOMAIN}${location.pathname}`}
                    quote={props.title}
                >
                    <FacebookIcon size={46} round />
                </FacebookShareButton>
            },
            {
                button: <WhatsappShareButton
                    url={`${process.env.REACT_APP_SHARE_DOMAIN}${location.pathname}`}
                    title={props.title}
                    separator=":: "
                >
                    <WhatsappIcon size={46} round />
                </WhatsappShareButton>
            }
        ])
    }, [props.title]);

    const { setIsOpen } = props

    function closeModal() {
        setIsOpen(false)
    }

    return <Popup {...props} >
        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
            <Dialog.Title
                as="h3"
                className="text-2xl font-medium leading-6 text-wsd mb-6"
            >
                {t('title', { ns: 'social' })}
            </Dialog.Title>
            <div className="flex justify-center  border-dashed border-t-2 border-wsd">
                <div className="mt-6 flex flex-col">
                    <span className="mx-2 inline-block text-gray-500 font-medium text-md">{t('desc', { ns: 'social' })}</span>
                    <div className="mt-6 flex w-full">

                        {shares.map((share, idx) => {
                            return <div key={idx} className="mx-2">{share.button}</div>
                        })}
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <button onClick={() => closeModal()} className="h-14 w-full p-2 mt-4 text-2xl rounded-full bg-wsd text-white font-semibold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-yellow-500">
                {t('back', { ns: 'social' })}
                </button>
            </div>
        </Dialog.Panel>
    </Popup>
}