import { Fragment, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faShareNodes, faFont } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom'

import Social from './Social'

export default function Header(props) {
    const [isSocialOpen, setIsSocialOpen] = useState(false);
    const { i18n, t } = useTranslation();
    const [searchParams] = useSearchParams();

    const lang = (lng) => {
        i18n.changeLanguage(lng);
    };

    if (searchParams.get('lang')) {
        if (['en', 'tc', 'sc'].includes(searchParams.get('lang'))) {
            lang(searchParams.get('lang'));
            searchParams.delete('lang');
        }
    }

    return (
        <Fragment>
            <Social
                isOpen={isSocialOpen}
                setIsOpen={setIsSocialOpen}
                title={props.title}
            />
            <section id="top">
                <div className='lg:flex lg:justify-between'>
                    <div className="flex px-4">
                        <a tabIndex="-1" href={`https://www.wsd.gov.hk/${i18n.language}/home/index.html`} target="_blank">
                            <div className="flex flex-none items-center h-16 pr-3">
                                {i18n.language === 'en' && <img className="w-72" src="/img/wsd-logo.png" alt="Water Supplies Department Home" />}
                                {i18n.language === 'tc' && <img className="w-36" src="/img/wsd-logo-tc.png" alt="Water Supplies Department Home" />}
                                {i18n.language === 'sc' && <img className="w-36" src="/img/wsd-logo-sc.png" alt="Water Supplies Department Home" />}
                            </div></a>

                        <div className="flex flex-none items-center h-16">
                            <a tabIndex="-1" href={`${process.env.REACT_APP_BOOKING_DOMAIN}/`}>
                                <div className="px-3 border-l-2 border-gray-100">
                                    <img className="w-10" src="/img/logo-ssp-reservoir.png" alt="Ex-Sham Shui Po Service Reservoir Self-guided Tour Booking System" />
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="flex px-4 justify-center h-16">
                        <div className="cursor-pointer flex flex-none items-center px-1" onClick={() => lang('en')}>
                            <div className={`${i18n.language === 'en' ? 'bg-wsd text-white' : 'bg-gray-200 text-wsd'} px-2 py-1 border-1 rounded-full font-meidum`}>
                                EN
                            </div>
                        </div>
                        <div className="cursor-pointer flex flex-none items-center px-1" onClick={() => lang('tc')}>
                            <div className={`${i18n.language === 'tc' ? 'bg-wsd text-white' : 'bg-gray-200 text-wsd'} px-2 py-1 border-1 rounded-full font-meidum`}>
                                繁
                            </div>
                        </div>
                        <div className="cursor-pointer flex flex-none items-center px-1" onClick={() => lang('sc')}>
                            <div className={`${i18n.language === 'sc' ? 'bg-wsd text-white' : 'bg-gray-200 text-wsd'} px-2 py-1 border-1 rounded-full font-meidum`}>
                                簡
                            </div>
                        </div>
                        <div className="flex flex-none items-center px-1">
                            <a tabIndex="-1" aria-label="Text Size Change Guide (Open In New Window)" href={`https://www.gov.hk/${i18n.language}/about/textsize`} target="_blank">
                                <div className="px-2 py-1 border-1 rounded-full bg-gray-200 text-wsd font-meidum focus:bg-wsd focus:text-white">
                                    <FontAwesomeIcon icon={faFont} alt="Text Size Change Guide (Open In New Window)" />
                                </div>
                            </a>
                        </div>

                        <div className="flex flex-none items-center px-1">
                            <div className="pl-2 border-l-2 border-gray-100">
                                <a tabIndex="-1" aria-label="Facebook (Open In New Window)" href={`https://www.facebook.com/watersavedave.hk`} target="_blank">
                                    <div className="px-2 py-1 border-1 rounded-full bg-gray-200 text-wsd font-meidum focus:bg-wsd focus:text-white">
                                        <FontAwesomeIcon icon={faFacebook} alt="Facebook (Open In New Window)" />
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="flex flex-none items-center px-1">
                            <a tabIndex="-1" aria-label="Youtube (Open In New Window)" href={`https://www.youtube.com/channel/UCBNvKbiFPLoawj7rZeDInmw`} target="_blank">
                                <div className="px-2 py-1 border-1 rounded-full bg-gray-200 text-wsd font-meidum focus:bg-wsd focus:text-white">
                                    <FontAwesomeIcon icon={faYoutube} alt="Youtube (Open In New Window)" />
                                </div>
                            </a>
                        </div>

                        <div className="flex flex-none items-center px-1">
                            <a tabIndex="-1" aria-label="Instagram (Open In New Window)" href={`https://www.instagram.com/watersavedave.hk/`} target="_blank">
                                <div className="px-2 py-1 border-1 rounded-full bg-gray-200 text-wsd font-meidum focus:bg-wsd focus:text-white">
                                    <FontAwesomeIcon icon={faInstagram} alt="Instagram (Open In New Window)" />
                                </div>
                            </a>
                        </div>

                        <div className="cursor-pointer  flex flex-none items-center px-1">
                            <div className="pl-2 border-l-2 border-gray-100">
                                <div onClick={() => setIsSocialOpen(true)} className="px-2 py-1 border-1 rounded-full bg-gray-200 text-wsd font-meidum focus:bg-wsd focus:text-white">
                                    <FontAwesomeIcon icon={faShareNodes} alt="Open Up The Page Sharing Dialog" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}